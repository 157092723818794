import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Context } from '../context/GlobalContext';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import { getTokenDeclension } from '../utils/declension';
import './AppBar.css'; // Import your styles for the AppBar

const AppBar = () => {
    const navigate = useNavigate();
    const { user, projects, selectedProject, setSelectedProject } = useContext(Context);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sideMenuOpen, setSideMenuOpen] = useState(false); // State to toggle side menu
    const dropdownRef = useRef(null); // Create a ref for the dropdown
    const [balance, setBalance] = useState('');

    useEffect(() => {
        if (user && user.token_amount) {
            setBalance(user.token_amount);
        }

    }, [user, balance, setBalance]);

    // Initialize selectedProject if projects exist
    useEffect(() => {
        if (projects.length > 0 && !selectedProject) {
            setSelectedProject(projects[0]); // Set to first project if none is selected
        }
    }, [projects, selectedProject, setSelectedProject, user]);

    const handleProjectChange = (project) => {
        if (project === '+ add new') {
            navigate('/create-project');
        } else {
            setSelectedProject(project); // Update selected project in context
        }
        setDropdownOpen(false); // Close the dropdown after selection
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleSideMenu = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    const toggleNavigateProjects = () => {
        navigate('/projects');
        toggleSideMenu();
    };

    const toggleNavigateAssistants = () => {
        navigate('/library');
        toggleSideMenu();
    };

    const toggleNavigatePrivacy = () => {
        navigate('/privacy');
        toggleSideMenu();
    };

    // Close the dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            {sideMenuOpen && <div className="blur-overlay" onClick={toggleSideMenu}></div>}

            <div className={`side-menu ${sideMenuOpen ? 'open' : ''}`}>
                <div className="side-menu-content">
                    <div className='menu-top'>
                        <div style={{color: '#333', marginTop: '50px'}}><span style={{fontWeight: 'bold', fontSize: '26px', lineHeight: '0'}}>Штучка</span><span style={{fontSize: '20px', lineHeight: '0'}}> - интеллект</span></div>
                        <div style={{color: '#333', marginTop: '10px', fontSize: '14px', lineHeight: '1.4'}}>Полезная штучка, разработанная маркетологами для маркетологов и предпринимателей</div>
                    </div>
                    <div className='menu-middle'>
                        <button style={{display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px'}} onClick={toggleNavigateProjects}>
                            <div>Мои проекты</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                        <button style={{display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px'}} onClick={toggleNavigateAssistants}>
                            <div>Библиотека штучек</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                    </div>
                    <div className='menu-bottom'>
                        <div style={{display: 'flex', justifyContent: 'space-around', paddingBottom: '20px'}}>
                            <img className='menu-social-item' src='/icon-telegram.png' style={{width: '30px', height: '30px' }} alt='telegram' />
                            <img className='menu-social-item' src='/icon-youtube.png' style={{width: '30px', height: '30px'}} alt='youtube' />
                            <img className='menu-social-item' src='/icon-instagram.png' style={{width: '30px', height: '30px'}} alt='instagram' />
                        </div>
                        <button>Публичная оферта</button>
                        <button onClick={toggleNavigatePrivacy}>Политика конфиденциальности</button>
                        <button>Пользовательское соглашение</button>
                        {/*<div>ИП Ложкина Вера Александровна<br />ИНН 1234567890 ОГРНИП 1234567890 mail@mail.ru</div>*/}
                    </div>
                </div>
            </div>
            <div className="appbar">
                <div className='appbar-wrapper'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', alignItems: 'center' }}>
                        <button className='menu-button' onClick={toggleSideMenu}>
                            <DragHandleIcon />
                        </button>
                        <div className="custom-dropdown" ref={dropdownRef}>
                            <div className='dropdown-button-wrapper'>
                            <button className="dropdown-button" onClick={toggleDropdown}>
                                {selectedProject ? selectedProject.name : 'Select Project'} {/* Ensure selectedProject is not null */}
                            </button>
                            </div>
                            {dropdownOpen && (
                                <ul className="dropdown-list">
                                    {projects.map((project, index) => (
                                        <li key={index} className="dropdown-item" onClick={() => handleProjectChange(project)}>
                                            {project.name} {/* Use project name */}
                                        </li>
                                    ))}
                                    <li className="dropdown-item" onClick={() => handleProjectChange('+ add new')}>
                                        + add new
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="appbar-navigation">
                    {user ? ( // Conditional rendering
                        <button style={{ fontFamily: '"Jost", sans-serif', textDecoration: 'underline', padding: '0' }} onClick={() => navigate('/tariffs')}>
                            {balance} {getTokenDeclension(balance)}
                        </button>
                    ) : (
                        <div>Loading</div> // Show loading state
                    )}
                </div>
                </div>
            </div>
        </>
    );
};

export default AppBar;
