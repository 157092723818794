import React, { createContext, useState } from 'react';

// Create a context
export const Context = createContext();

// Provider component
export const ContextProvider = ({ children }) => {
    // State for projects
    const [user, setUser] = useState(null);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [webApp, setWebApp] = useState(null);
    const updateUserTokens = (newTokenAmount) => {
        setUser((prevUser) => ({
            ...prevUser,
            token_amount: newTokenAmount,
        }));
    };
    

    return (
        <Context.Provider
            value={{
                user, setUser,
                projects, setProjects,
                selectedProject, setSelectedProject,
                updateUserTokens,
            }}
        >
            {children}
        </Context.Provider>
    );
};
