import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import { TelegramThemeContext } from '../context/TelegramThemeContext';
import axios from 'axios';
import StatusBar from '../components/StatusBar';

const Library = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const { user, projects } = useContext(Context);
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const appEnv = process.env.REACT_APP_ENV;
  const [error, setError] = useState('');

  const theme = useContext(TelegramThemeContext);

  const tabs = useMemo(() => ['Создание контента', 'Создание материалов'], []);

  const fetchPrompts = useCallback(async (currentPage) => {
    try {
      setLoading(true);
      const category = tabs[activeTab];
      const response = await axios.get(`${apiUrl}/api/prompts`, {
        params: {
          category,  // Filter by category
          page,      // Pagination page number
          limit: 100, // Number of items per page
        },
      });

      const newPrompts = response.data;

      // If no more prompts, stop pagination
      if (newPrompts.length === 0) {
        setHasMore(false);
      } else {
        // Update prompts; reset on first page load, otherwise append
        setPrompts((prev) => (currentPage === 1 ? newPrompts : [...prev, ...newPrompts]));
        setHasMore(true); // There are more entries if length > 0
      }
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setError('Error loading data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [activeTab, apiUrl, tabs]);

  useEffect(() => {
    setPage(1);
    fetchPrompts(1);
  }, [activeTab, fetchPrompts]);

  /*useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, hasMore]);

  useEffect(() => {
    if (page > 1) {
      fetchPrompts(page);
    }
  }, [page, fetchPrompts]);

  */

  const handleTabClick = (index) => {
    setActiveTab(index);

    const tabElement = document.getElementById(`tab-${index}`);
    const container = document.querySelector('.tabs-scroll');

    if (tabElement) {
      tabElement.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling effect
        inline: 'start', // Scroll the tab to the start (left side)
      });
    }
  };

  const handleBlockClick = (block) => {
    navigate('/assistant', {
      state: {
        id: block.id,
        name: block.name,
        description: block.description,
        field: block.field,
        hint: block.hint,
      },
    });
  };

  return (
    <div className='wrapper'>
              <StatusBar />
              <div className="tabs-scroll">
            {tabs.map((tab, index) => (
              <button
                key={index}
                id={`tab-${index}`}
                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab}
              </button>
            ))}
          </div>
      <div className='wrapper1' style={{marginTop: '0'}}>
        {/*{appEnv === 'development' && <div>Среда: {appEnv}</div>}
        {appEnv === 'local' && <div>Среда: {appEnv}</div>}
        <div>
          {loading ? (
            <div>Loading user data...</div>
          ) : (
            <div>User: {user?.telegram_id}</div>
          )}
          </div>*/}

        <div className="tabs-container">


          {loading && <div>Loading data...</div>}

          <div className="tab-content">
            {prompts.map((block, index) => (
              <div key={index} className="tab-block" onClick={() => handleBlockClick(block)}>
                <div className='tab-block-header'>
                  <h3 className='font'>{block.name}</h3>
                </div>
                <p className='font'>{block.description}</p>
              </div>
            ))}

            {/*{!loading && !hasMore && <div>No more data to load</div>}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Library;
