import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Privacy = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className='wrapper'>
            <div className='wrapper1' style={{ marginTop: '70px' }}>
                <h1 style={{ marginBottom: '4px', lineHeight: 1.4 }}>Политика в отношении обработки персональных данных</h1>
                <p style={{ textAlign: 'left', fontSize: '12px', margin: '0 0 20px 0', padding: '0' }}>Утверждено 7 октября 2024г.</p>
                <h2>1. Общие положения</h2>
                    <p>Настоящая политика обработки персональных данных (далее - Политика) составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые индивидуальным предпринимателем Ложкиной Верой Александровной (далее — Оператор).</p>
                    <p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
                    <p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://штучка-интеллект.рф">https://штучка-интеллект.рф</a>, всех его разделов, подразделов, субдоменов, а также Телеграм-бота <a href="https://t.me/aithingybot">https://t.me/aithingybot</a> (совместно именуемых далее - Сервис).</p>
                    <p>1.3. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных настоящая Политика публикуется в свободном доступе в информационно-телекоммуникационной сети Интернет на сайте <a href="https://штучка-интеллект.рф">https://штучка-интеллект.рф</a>.</p>
                <h2>2. Основные понятия, используемые в Политике:</h2>
                <p>Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому Пользователю Сервиса.</p>
                <p>Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее— персональные данные, разрешенные для распространения).</p>
                <p>Пользователь — любой посетитель Сервиса, в том числе предоставивший Сервису свои персональные данные.</p>
                <p>Оператор персональных данных (оператор) - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p>Обработка персональных данных - любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования.</p>
                <p>Обработка персональных данных включает: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                <p>Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники.</p>
                <p>Распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</p>
                <p>Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                <p>Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
                <p>Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.</p>
                <p>Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</p>
                <p>Информационная система персональных данных - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>

                    <h2>3. Основные права и обязанности Оператора.</h2>

                    <p>3.1 Оператор имеет право:</p>
                    <ul>
                    <li>Получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные.</li>
                    <li>Самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.</li>
                    <li>Поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по поручению Штучка-интеллекта, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Законом о персональных данных, соблюдать конфиденциальность персональных данных, принимать необходимые меры, направленные на обеспечение выполнения обязанностей, предусмотренных Законом о персональных данных.</li>
                    <li>В случае отзыва субъектом персональных данных согласия на обработку персональных данных Штучка-интеллект вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных.</li>
                    </ul>
                    <p>3.2. Оператор обязан:</p>
                    <ul>
                    <li>Организовывать обработку персональных данных в соответствии с требованиями Закона о персональных данных.</li>
                    <li>Отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных.</li>
                    <li>Сообщать в уполномоченный орган по защите прав субъектов персональных данных (Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)) по запросу этого органа необходимую информацию в течение 10 рабочих дней с даты получения такого запроса. Данный срок может быть продлен, но не более чем на пять рабочих дней. Для этого Оператору необходимо направить в Роскомнадзор мотивированное уведомление с указанием причин продления срока предоставления запрашиваемой информации;</li>
                    <li>В порядке, определенном федеральным органом исполнительной власти, уполномоченным в области обеспечения безопасности, обеспечивать взаимодействие с государственной системой обнаружения, предупреждения и ликвидации последствий компьютерных атак на информационные ресурсы РФ, включая информирование его о компьютерных инцидентах, которые повлекли неправомерную передачу (предоставление, распространение, доступ) персональных данных.</li>
                    </ul>

                    <h2>4. Основные права и обязанности субъекта персональных данных.</h2>

                    <p>4.1. Субъект персональных данных имеет право:</p>
                    <p>Получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Штучка-интеллектом в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных.</p>
                    <p>Требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                    <p>Дать предварительное согласие на обработку персональных данных в целях продвижения на рынке товаров, работ и услуг.</p>
                    <p>Обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия или бездействие Штучка-интеллекта при обработке его персональных данных.</p>
                    
                    <p>4.2. Субъекты персональных данных обязаны:</p>
                    <p>предоставлять Оператору достоверные данные о себе;</p>
                    <p>сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.</p>
                    <p>4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.</p>
                    <p>4.4. Ответственность за нарушение требований законодательства Российской Федерации и локальных актов ИП Ложкина Вера Александровна в сфере обработки и защиты персональных данных определяется в соответствии с законодательством Российской Федерации.</p>

                    <h2>5. Принципы обработки персональных данных</h2>

                    <p>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</p>
                    <p>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
                    <p>5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.</p>
                    <p>5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
                    <p>5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.</p>
                    <p>5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.</p>
                    <p>5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>


                    <h2>6. Цели сбора персональных данных</h2>

                    <p>6.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
                    <p>6.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
                    <p>6.3. Обработка Оператором персональных данных осуществляется в следующих целях:</p>
                    <ul>
                        <li>осуществление ИП Ложкина Вера Александровна своей деятельности;</li>
                        <li>обеспечение соблюдения законов и иных нормативных правовых актов;</li>
                        <li>ведение операционного и бухгалтерского учета;</li>
                        <li>обеспечение идентификации пользователей в Сервисе;</li>
                        <li>обеспечение корректной работы всех заявленных функций Сервиса;</li>
                        <li>заключение договоров с клиентами (контрагентами) в рамках своей деятельности;</li>
                        <li>информирование пользователей Сервиса;</li>
                    </ul>

                    <p>6.4. Обработка персональных данных работников может осуществляться исключительно в целях обеспечения соблюдения законов и иных нормативных правовых актов.</p>

                    <h2>7. Правовые основания обработки персональных данных</h2>

                    <p>7.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том числе: Конституция Российской Федерации; Гражданский кодекс Российской Федерации; Трудовой кодекс Российской Федерации; Налоговый кодекс Российской Федерации; Федеральный закон от 27.07.2006 № 152-ФЗ «О персональных данных»; Федеральный закон от 06.12.2011 № 402-ФЗ «О бухгалтерском учете»; Федеральный закон от 15.12.2001 № 167-ФЗ «Об обязательном пенсионном страховании в Российской Федерации»; иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.</p>
                    <p>7.2. Правовым основанием обработки персональных данных также являются:</p>
                    <ul>
                        <li>договоры, заключаемые между Оператором и субъектами персональных данных, в том числе договоры оферты;</li>
                        <li>согласие субъектов персональных данных на обработку их персональных данных.</li>
                    </ul>
                    <h2>8. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных</h2>
                    <p>8.1. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки, предусмотренным в раздела 2 настоящей Политики. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.</p>
                    <p>8.2. Оператор может обрабатывать следующие персональные данные:</p>
                    <ul>
                        <li>фамилия, имя, отчество;</li>
                        <li>год рождения; месяц рождения; дата рождения;</li>
                        <li>пол;</li>
                        <li>адрес электронной почты;</li>
                        <li>номер телефона;</li>
                        <li>данные сервисов, посредством которых осуществляется авторизация Пользователя в Сервисе.</li>
                    </ul>

                    <h2>9. Порядок и условия обработки персональных данных</h2>

                    <p>9.1. Обработка персональных данных осуществляется в соответствии с требованиями законодательства Российской Федерации.</p>
                    <p>9.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных на обработку их персональных данных, а также без такового в случаях, предусмотренных законодательством Российской Федерации.</p>
                    <p>9.3. Оператор осуществляет обработку персональных данных для каждой цели их обработки следующими способами:</p>
                    <ul>
                        <li>неавтоматизированная обработка персональных данных;</li>
                        <li>автоматизированная обработка персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям или без таковой;</li>
                        <li>смешанная обработка персональных данных.</li>
                    </ul>

                    <p>9.4. Обработка персональных данных для каждой цели обработки, указанной в п. 2.3 Политики, осуществляется путем:</p>
                    <ul>
                        <li>получения персональных данных в устной и письменной форме непосредственно от субъектов персональных данных;</li>
                        <li>внесения персональных данных в журналы, реестры и информационные системы Оператора;</li>
                        <li>использования иных способов обработки персональных данных.</li>
                    </ul>
                    <p>9.5. Не допускается раскрытие третьим лицам и распространение персональных данных без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом. Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения, оформляется отдельно от иных согласий субъекта персональных данных на обработку его персональных данных.</p>
                    <p>9.6. Передача персональных данных органам дознания и следствия, в Федеральную налоговую службу, Социальный фонд России и другие уполномоченные органы исполнительной власти и организации осуществляется в соответствии с требованиями законодательства Российской Федерации.</p>
                    <p>9.7. Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения и других несанкционированных действий, в том числе:</p>
                    <ul>
                        <li>определяет угрозы безопасности персональных данных при их обработке;</li>
                        <li>принимает локальные нормативные акты и иные документы, регулирующие отношения в сфере обработки и защиты персональных данных;</li>
                        <li>назначает лиц, ответственных за обеспечение безопасности персональных данных в структурных подразделениях и информационных системах Оператора;</li>
                        <li>создает необходимые условия для работы с персональными данными;</li>
                        <li>организует учет документов, содержащих персональные данные;</li>
                        <li>организует работу с информационными системами, в которых обрабатываются персональные данные;</li>
                        <li>хранит персональные данные в условиях, при которых обеспечивается их сохранность и исключается неправомерный доступ к ним;</li>
                        <li>организует обучение работников, осуществляющих обработку персональных данных.</li>
                    </ul>

                    <p>9.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требует каждая цель обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором.</p>
                    <p>9.9. Оператор прекращает обработку персональных данных в следующих случаях:</p>
                    <ul>
                        <li>Выявлен факт их неправомерной обработки. Срок - в течение трех рабочих дней с даты выявления.</li>
                        <li>Достигнута цель их обработки.</li>
                        <li>Истек срок действия или отозвано согласие субъекта персональных данных на обработку указанных данных, когда по Закону о персональных данных обработка этих данных допускается только с согласия.</li>
                    </ul>
=
                    <p>9.10. При обращении субъекта персональных данных с требованием о прекращении обработки персональных данных в срок, не превышающий 10 рабочих дней с даты получения соответствующего требования, обработка персональных данных прекращается, за исключением случаев, предусмотренных Законом о персональных данных. Указанный срок может быть продлен, но не более чем на пять рабочих дней. Для этого необходимо направить субъекту персональных данных мотивированное уведомление с указанием причин продления срока.</p>
                    <p>9.11. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети Интернет, Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в Законе о персональных данных.</p>

                    <h2>10. Перечень действий, производимых Оператором с полученными персональными данными</h2>
                    <p>10.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
                    <p>10.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>

                    <h2>11. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.</h2>

                    <h2>12. Заключительные положения</h2>
                    <p>12.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:akane@inbox.ru">akane@inbox.ru</a>.</p>
                    <p>12.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
                    <p>12.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://штучка-интеллект.рф/privacy">https://штучка-интеллект.рф/privacy</a>.</p>

                    <p>Индивидуальный предприниматель<br />Ложкина Вера Александровна</p>
                    <p>ИНН 860225526635<br />ОГРНИП 319861700052149<br />Электронная почта: <a href="mailto:akane@inbox.ru">akane@inbox.ru</a></p>
            </div>
        </div>
    );
};

export default Privacy;
