import React from 'react';
import { useLocation } from 'react-router-dom';
import './StatusBar.css'; // External CSS file

const StatusBar = ({ streamedData, fullResponse }) => {
    const location = useLocation();

    // Define the status bar items with conditions for active states
    const statusItems = [
        { id: 1, icon: '🔍', description: 'Выберите\nштучку', isActive: location.pathname === '/library' && !streamedData },
        { id: 2, icon: '💬', description: 'Уточните\nзапрос', isActive: location.pathname !== '/library' && !streamedData },
        { id: 3, icon: '✅', description: 'Получите\nрезультат', isActive: location.pathname !== '/library' && streamedData },
    ];

    return (
        <div className="status-bar">
            {statusItems.map((item) => (
                <div
                    key={item.id}
                    className={`status-item ${item.isActive ? 'active' : 'inactive'}`}
                >
                    <div className="status-icon">{item.icon}</div>
                    <div className="status-description">{item.description}</div>
                </div>
            ))}
        </div>
    );
};

export default StatusBar;
