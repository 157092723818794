import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { sliderConfig, calculatePrice, buttonOptions } from '../config/pricingConfig';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './Tariffs.css'

const Tariffs = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='wrapper'>
            <div className='wrapper1' style={{marginTop: '70px'}}>
                <div className="tab-block-header">
                    <div
                        style={{ marginRight: '10px', transform: 'translateY(4px)' }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIosNewRoundedIcon />
                    </div>
                </div>
                <h1>Баланс токенов</h1>
                    <div>Ваш баланс:</div>
                <button className='action-button' style={{position: 'fixed', bottom: '20px', width: '90%'}} onClick={() => window.open('/tokens', '_blank')}>Получить токены</button>
            </div>
        </div>
    );
};

export default Tariffs;

