import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { sliderConfig, calculatePrice, buttonOptions } from '../config/pricingConfig';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './Tariffs.css'

const Tokens = () => {
    const navigate = useNavigate();
    const location = useLocation();

    

    return (
        <div className='wrapper'>
            <div className='wrapper1'>
                <div className="tab-block-header">
                    <h1>Тарифы</h1>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <button style={{border: 'none', borderRadius: '10px', marginBottom: '20px', background: 'rgba(255, 255, 255, 0.5)', padding: '10px 20px'}}>10000 токенов - 490 рублей</button>
                    <button style={{border: 'none', borderRadius: '10px', marginBottom: '20px', background: 'rgba(255, 255, 255, 0.5)', padding: '10px 20px'}}>50000 токенов - 1990 рублей</button>
                    <button style={{border: 'none', borderRadius: '10px', marginBottom: '20px', background: 'rgba(255, 255, 255, 0.5)', padding: '10px 20px'}}>100000 токенов - 2990 рублей</button>
                </div>
                <button className='action-button'>Получить токены</button>
            </div>
        </div>
    );
};

export default Tokens;

