export function getTokenDeclension(count) {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return 'токенов';
    }

    if (lastDigit === 1) {
        return 'токен';
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
        return 'токена';
    }

    return 'токенов';
}
