import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Context } from '../context/GlobalContext';
import LoadingState from '../components/LoadingState';
import { TelegramThemeContext } from '../context/TelegramThemeContext';
import StatusBar from '../components/StatusBar';

const Assistant = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, name, description, field, hint, fieldValue: initialFieldValue = '' } = location.state || {};
    const { user, selectedProject } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [streamedData, setStreamedData] = useState('');
    const [error, setError] = useState(null);
    const [fieldValue, setFieldValue] = useState(initialFieldValue || '');
    const socketRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100;
    const [fullResponse, setFullResponse] = useState('');
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [pdfSent, setPdfSent] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    let accumulatedResponse = '';
    const bot_url = process.env.REACT_APP_BOT_URL;
    const [balance, setBalance] = useState('');

    const theme = useContext(TelegramThemeContext);


    useEffect(() => {
        console.log(bot_url)
        return () => {
            sessionStorage.setItem('fieldValue', fieldValue);
        };
    }, [fieldValue, bot_url]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setStreamedData('');

        if (socketRef.current) {
            socketRef.current.close();
        }

        const socket = new WebSocket(process.env.REACT_APP_WS_URL, null);
        socketRef.current = socket;

        socket.onopen = () => {
            console.log('socket opened');
            socket.send(JSON.stringify({
                id,
                selectedProject,
                fieldValue,
            }));
        };

        socket.onmessage = (event) => {
            console.log('Message from server:', event.data); // Log the incoming message
            const message = event.data;
            if (typeof message === 'string') {
                try {
                    // Attempt to parse the string as JSON
                    const parsedData = JSON.parse(message);
                    console.log('Received valid JSON:', parsedData);
                    if (parsedData.type === 'TOKEN_UPDATE') {
                        // Update the token balance in the frontend
                        //setBalance(parsedData.updatedBalance);
                        console.log('updated balance: ', parsedData.updatedBalance);
                    }
                } catch (error) {
                    console.log('Received a non-JSON string:', message);
                    if (event.data === 'DONE') {
                        console.log('Stream finished');
                        setFullResponse(accumulatedResponse);
                        setIsLoading(false);
                        console.log('Full Response:', accumulatedResponse);
                    } else {
                        // Update streamed data as it arrives
                        setIsLoading(false);
                        accumulatedResponse += event.data;
                        setStreamedData((prev) => prev + event.data);
                    }
                }
            } else {
                console.log('Received non-string data:', message);
            }
        };

        socket.onerror = (err) => {
            console.error('WebSocket error:', err);
            setError('WebSocket connection error.');
            setIsLoading(false);
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed.');
            console.log(`Code: ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
            if (event.code !== 1000) { // 1000 means "normal closure"
                console.error('WebSocket closed unexpectedly with code:', event.code);
            }
        };

        // Clean up WebSocket when the component unmounts
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    };

    const toggleShowMore = () => {
        setIsExpanded(!isExpanded);
    };//

    const handleGetPdf = async () => {
        setIsButtonLoading(true);
        const chatId = user.telegram_id;
        console.log(chatId, fullResponse)
        try {
            const response = await fetch(`${apiUrl}/api/getPdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatId: chatId,
                    content: fullResponse
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setPdfSent(true);
            setIsButtonLoading(false);
        }
        catch (error) {
            console.error('Error while generating pdf:', error);
            setIsButtonLoading(false);
        }
    }

    const getFontSize = (length) => {
        if (length > 499) return '14px';
        if (length > 99 && length < 500) return '16px';
        if (length > 49 && length < 100) return '18px';
        if (length > 19 && length < 50) return '20px';
        if (length < 20) return '26px';
        return '20px';
    };

    return (
        <div className="wrapper">
            <div className="wrapper1">
                <StatusBar streamedData={streamedData} fullResponse={fullResponse} />
                {isLoading ? (
                    <LoadingState />
                ) : (
                    <div>
                        <div className="template-block">
                            <div className="tab-block-header" onClick={() => navigate(-1)} style={{ marginRight: '10px', cursor: 'pointer', marginBottom: '10px' }}>
                                <ArrowBackIosNewRoundedIcon style={{ width: '14px', marginRight: '6px' }} />
                                <div>Назад</div>
                            </div>
                            <h1>{name}</h1>
                            <div><span style={{ fontWeight: '500' }}>Описание штучки: </span>{description}</div>
                            <div style={{ marginTop: '20px', marginBottom: '20px' }}><span style={{ fontWeight: '500' }}>Выбранный проект:</span> {selectedProject.name}</div>
                        </div>

                        {streamedData ? (
                            <div>
                                <div className="streamed-response" style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                                    <h3>Streaming Data:</h3>
                                    <p style={{ marginBottom: '80px' }}>{streamedData}</p>
                                </div>
                                {error && (
                                    <div>
                                        <div className="error-message">{error}</div>
                                        <button className='action-button' onClick={handleSubmit}>
                                            {isButtonLoading ? 'Загрузка...' : 'Попробовать снова'}
                                        </button>
                                    </div>
                                )}
                                <div style={{ position: 'fixed', bottom: '20px', width: '90%' }}>
                                    {fullResponse && !pdfSent && (
                                        <button className='action-button' onClick={handleGetPdf}>
                                            {isButtonLoading ? 'Загрузка...' : 'Скачать PDF'}
                                        </button>
                                    )}
                                    {pdfSent && <div className="pdf-sent-message" style={{ textAlign: 'center', fontWeight: '500', marginTop: '10px', fontSize: '16px' }}>PDF-документ отправлен в <a style={{ textDecoration: 'underline', color: '#555', }} href={process.env.REACT_APP_BOT_URL}>бот</a> </div>}
                                </div>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit} className="project-form">
                                <div className="form-group">
                                    <label htmlFor="additionalField1">{field}</label>
                                    <div className={`assistant-hint-block ${isExpanded ? 'expanded-hint-block' : ''}`}>
                                        <div maxLength={100} className={`assistant-hint ${isExpanded ? 'expanded-hint' : ''}`}>Подсказка: {isExpanded ? hint : `${hint.slice(0, maxLength)}...`}</div>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the default behavior of the form button
                                                toggleShowMore();
                                            }}
                                            style={{ border: 'none', background: 'none', textDecoration: 'underline', fontFamily: '"Jost", sans-serif', fontSize: '14px', color: '#333', padding: '0 0 0 10px' }}
                                        >
                                            {isExpanded ? "Свернуть" : "Подробнее"}
                                        </button>
                                    </div>
                                    <textarea
                                        type="text"
                                        id="additionalField1"
                                        value={fieldValue}
                                        onChange={(e) => setFieldValue(e.target.value)}
                                        placeholder="Ваш ответ"
                                        style={{ fontSize: getFontSize(fieldValue.length) }}
                                        required
                                    />
                                </div>

                                <button type="submit" className="gradient-button">
                                    Начать
                                    <span className="gradient-button-icon">✨</span>
                                </button>
                            </form>

                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Assistant;